import React, { useState, useEffect, useRef } from 'react';
import { Table, Row, Col, Input, Button } from 'reactstrap';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { Scrollbar } from 'react-scrollbars-custom';
import { SAMPLE_RATE } from '../../helpers/jamTracks';
import { useJamTrack } from '../../hooks/useJamTrack';
import JKSigningEstimateTimeModal from './JKSigningEstimateTimeModal';


const JKCreateCustomMix = () => {
  const MAX_MIXDOWNS = 5;
  const [tracks, setTracks] = useState([]);
  const [selectedTracks, setSelectedTracks] = useState([]);
  const scrollbar = useRef();
  const TEMPO_OPTIONS = [
    { value: '0', label: 'Original tempo' },
    { value: '-5', label: 'Slower by 5%' },
    { value: '-10', label: 'Slower by 10%' },
    { value: '-15', label: 'Slower by 15%' },
    { value: '-20', label: 'Slower by 20%' },
    { value: '-25', label: 'Slower by 25%' },
    { value: '-30', label: 'Slower by 30%' },
    { value: '-35', label: 'Slower by 35%' },
    { value: '-40', label: 'Slower by 40%' },
    { value: '-45', label: 'Slower by 45%' },
    { value: '-50', label: 'Slower by 50%' },
    { value: '-60', label: 'Slower by 60%' },
    { value: '-70', label: 'Slower by 70%' },
    { value: '-80', label: 'Slower by 80%' },
    { value: '5', label: 'Faster by 5%' },
    { value: '10', label: 'Faster by 10%' },
    { value: '15', label: 'Faster by 15%' },
    { value: '20', label: 'Faster by 20%' },
    { value: '30', label: 'Faster by 30%' },
    { value: '40', label: 'Faster by 40%' },
    { value: '50', label: 'Faster by 50%' }
  ];
  const PITCH_OPTIONS = [
    { value: '0', label: 'Original pitch' },
    { value: '-1', label: 'Down 1 semitone' },
    { value: '-2', label: 'Down 2 semitone' },
    { value: '-3', label: 'Down 3 semitone' },
    { value: '-4', label: 'Down 4 semitone' },
    { value: '-5', label: 'Down 5 semitone' },
    { value: '-6', label: 'Down 6 semitone' },
    { value: '-7', label: 'Down 7 semitone' },
    { value: '-8', label: 'Down 8 semitone' },
    { value: '-9', label: 'Down 9 semitone' },
    { value: '-10', label: 'Down 10 semitone' },
    { value: '-11', label: 'Down 11 semitone' },
    { value: '-12', label: 'Down 12 semitone' },
    { value: '1', label: 'Up 1 semitone' },
    { value: '2', label: 'Up 2 semitone' },
    { value: '3', label: 'Up 3 semitone' },
    { value: '4', label: 'Up 4 semitone' },
    { value: '5', label: 'Up 5 semitone' },
    { value: '6', label: 'Up 6 semitone' },
    { value: '7', label: 'Up 7 semitone' },
    { value: '8', label: 'Up 8 semitone' },
    { value: '9', label: 'Up 9 semitone' },
    { value: '10', label: 'Up 10 semitone' },
    { value: '11', label: 'Up 11 semitone' },
    { value: '12', label: 'Up 12 semitone' }
  ];

  const { jamTrack, mixdowns, createMixdown, newMixdownLoadingStatus, awaitingMixdown, enqueueMixdownForSigning, enqueuedMixdown, showQueueTime, setShowQueueTime, enqueueTimeMessage, showEstimatedTime, manageWatchedMixdowns, trackName } = useJamTrack();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      mixName: '',
      tempo: {
        value: '0',
        label: 'Original tempo'
      },
      pitch: {
        value: '0',
        label: 'Original pitch'
      },
      mixdownTracks: []
    }
  });

  const onSubmit = data => {
    const _tracks = [];
    let countIn = false;
    const selected = getValues('mixdownTracks');
    tracks.forEach(track => {
      const muted = selected.includes(track.id);
      if (track.id === 'count-in') {
        if (countIn === false) {
          countIn = !muted;
        }
      } else {
        _tracks.push({
          id: track.id,
          mute: selected.includes(track.id)
        });
      }
    });

    setValue('mixdownTracks', _tracks);

    const mixData = {
      jamTrackID: jamTrack.id,
      name: data.mixName,
      settings: {
        speed: parseInt(data.tempo.value),
        pitch: parseInt(data.pitch.value),
        'count-in': countIn,
        tracks: _tracks
      }
    };

    //const tempMixdown = { ...mixData, id: 'temp', jam_track_id: jamTrack.id };
    //dispatch(addMixdown(tempMixdown));

    // dispatch(createMyMixdown(mixData));
    createMixdown(mixData);
  };

  const toggleTrack = e => {
    const trackId = e.target.value;
    const selected = getValues('mixdownTracks');
    if (selected.includes(trackId)) {
      setValue('mixdownTracks', selectedTracks.filter(track => track !== trackId));
    } else {
      setValue('mixdownTracks', [...selectedTracks, trackId]);
    }
    setSelectedTracks(getValues('mixdownTracks'));
  };

  useEffect(() => {
    if (jamTrack) {
      setTracks(jamTrack.tracks.filter(track => track.track_type === 'Track' || track.track_type === 'Click'));
    }

  }, [jamTrack]);

  useEffect(() => {
    if (newMixdownLoadingStatus === 'succeeded') {
      setValue('mixName', '');
      setValue('tempo', TEMPO_OPTIONS[0]);
      setValue('pitch', PITCH_OPTIONS[0]);
      setValue('mixdownTracks', []);
      setSelectedTracks([]);
    }
  }, [newMixdownLoadingStatus]);


  useEffect(() => {
    if (awaitingMixdown) {
      //enqueue the mixdown
      console.log('*_DEBUG_ awaitingMixdown', awaitingMixdown);
      const options = { 
        id: awaitingMixdown.id, 
        file_type: 'mp3', 
        encrypt_type: null, 
        sample_rate: SAMPLE_RATE,
        origin: 'create'
      };
      enqueueMixdownForSigning(options);
    }
  }, [awaitingMixdown]);

  useEffect(() => {
    if (enqueuedMixdown && enqueuedMixdown.origin === 'create') {
      console.log('*_DEBUG_ enqueuedMixdown from create', enqueuedMixdown);
      showEstimatedTime();
      manageWatchedMixdowns();
    }
  }, [enqueuedMixdown]);


  // const trackName = track => {
  //   if (track.track_type === 'Track' || track.track_type === 'Click') {
  //     if (track.track_type === 'Click') {
  //       return 'Clicktrack';
  //     } else if (track.instrument) {
  //       const instrumentDescription = track.instrument.description;
  //       let part = '';
  //       if (track.part && track.part !== instrumentDescription) {
  //         part = `(${track.part})`;
  //       }
  //       return `${instrumentDescription} ${part}`;
  //     }
  //   }
  // };

  const hasExceededMax = mixdowns.length >= MAX_MIXDOWNS;

  return (
    <>
      <p>
        Mute any tracks you like. Adjust the pitch or tempo of playback. Then give your mix a descriptive name, and
        click the Create Mix button. It will take few minutes for us to create your custom mix.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Scrollbar ref={scrollbar} style={{ width: '100%', height: 300 }} mobileNative={true}>
              <Table striped bordered className="fs--1 mb-0">
                <thead className="bg-200 text-900">
                  <tr>
                    <th>Tracks {tracks.length > 0 && <>({tracks.length})</>}</th>
                    <th className="text-center">Mute</th>
                  </tr>
                </thead>
                <tbody>
                  {tracks &&
                    tracks.map((track, index) => (
                      <tr key={index}>
                        <td>
                          <span>{trackName(track)}</span>
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            value={track.id}
                            onChange={toggleTrack}
                            checked={selectedTracks.includes(track.id)}
                            disabled={hasExceededMax}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Scrollbar>
            <Controller
              name="mixdownTracks"
              control={control}
              rules={{
                required: 'Select at least one track to create a mix'
              }}
              render={({ field }) => <Input type="hidden" {...field} />}
            />
            {errors.mixdownTracks && (
              <div className="text-danger">
                <small>{errors.mixdownTracks.message}</small>
              </div>
            )}
          </Col>
        </Row>

        <Row className="mb-3 mt-3">
          <Col sm={6} md={4} lg={3}>
            Tempo
          </Col>
          <Col>
            <Controller
              name="tempo"
              control={control}
              render={({ field }) => <Select {...field} options={TEMPO_OPTIONS} isDisabled={hasExceededMax} />}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6} md={4} lg={3}>
            Pitch
          </Col>
          <Col>
            <Controller
              name="pitch"
              control={control}
              render={({ field }) => <Select {...field} options={PITCH_OPTIONS} isDisabled={hasExceededMax} />}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6} md={4} lg={3}>
            Mix Name
          </Col>
          <Col>
            <Controller
              name="mixName"
              control={control}
              rules={{
                required: 'Mix name is required'
              }}
              render={({ field }) => <Input {...field} disabled={hasExceededMax} />}
            />
            {errors.mixName && (
              <div className="text-danger">
                <small>{errors.mixName.message}</small>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button color="primary" disabled={newMixdownLoadingStatus === 'loading' || hasExceededMax}>
              {newMixdownLoadingStatus === 'loading' ? 'Creating Mix...' : 'Create Mix'}
            </Button>
          </Col>
        </Row>
      </form>

      <JKSigningEstimateTimeModal
        showQueueTime={showQueueTime}
        setShowQueueTime={setShowQueueTime}
        enqueueTimeMessage={enqueueTimeMessage}
      />
    </>
  );
};

export default JKCreateCustomMix;
