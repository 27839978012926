import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const JKAffiliatePayeeAddress = ({ affiliateUser, onSubmit, submitting }) => {
  const { t } = useTranslation('affiliate');
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    defaultValues: {
      phone_last_4: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postal_code: '',
      country: ''
    }
  });

  useEffect(() => {
    setValue('phone_last_4', affiliateUser?.address?.phone_last_4 || '');
    setValue('address1', affiliateUser?.address?.address1 || '');
    setValue('address2', affiliateUser?.address?.address2 || '');
    setValue('city', affiliateUser?.address?.city || '');
    setValue('state', affiliateUser?.address?.state || '');
    setValue('postal_code', affiliateUser?.address?.postal_code || '');
    setValue('country', affiliateUser?.address?.country || '');
  }, [affiliateUser]);

  const onSubmitAddress = data => {
    const params = { "address": data };
    onSubmit(params);
  }

  return (
    <Card>
      <CardHeader>
        <h5>{t('payee.address.title')}</h5>
      </CardHeader>
      <CardBody className="bg-light" style={{ minHeight: 300 }}>
        <small>
          {t('payee.address.help_text')}
        </small>
        <Form noValidate className="mt-3" onSubmit={handleSubmit(onSubmitAddress)}>
          <FormGroup>
            <Label for="phone_last_4">{t('payee.address.form.phone_last_4')}</Label>
            <Controller
              name="phone_last_4"
              control={control}
              rules={{
                required: t('payee.address.form.validations.phone_last_4.required')
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="form-control"
                  id="phone_last_4"
                  maxLength={4}
                />
              )}
            />
            {errors.phone_last_4 && (
              <div className="text-danger">
                <small>{errors.phone_last_4.message}</small>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="address1">{t('payee.address.form.address1')}</Label>
            <Controller
              name="address1"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="form-control"
                  id="address1"
                />
              )}
            />
            {errors.address1 && (
              <div className="text-danger">
                <small>{errors.address1.message}</small>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="address2">{t('payee.address.form.address2')}</Label>
            <Controller
              name="address2"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="form-control"
                  id="address2"
                />
              )}
            />
            {errors.address2 && (
              <div className="text-danger">
                <small>{errors.address2.message}</small>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="city">{t('payee.address.form.city')}</Label>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="form-control"
                  id="city"
                />
              )}
            />
            {errors.city && (
              <div className="text-danger">
                <small>{errors.city.message}</small>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="state">{t('payee.address.form.state')}</Label>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="form-control"
                  id="state"
                />
              )}
            />
            {errors.state && (
              <div className="text-danger">
                <small>{errors.state.message}</small>
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="postal_code">{t('payee.address.form.zip')}</Label>
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="text"
                  className="form-control"
                  id="postal_code"
                />
              )}
            />
            {errors.state && (
              <div className="text-danger">
                <small>{errors.postal_code.message}</small>
              </div>
            )}
          </FormGroup>
          <div className='d-flex align-content-center justify-content-end'>
            <input type="submit" formNoValidate className="btn btn-primary" value={t('payee.address.form.submit')} disabled={submitting} data-testid="address_submit" />
            <span className='ml-2'>
              { submitting && <FontAwesomeIcon icon="spinner" />}
            </span>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default JKAffiliatePayeeAddress;
