import React from 'react';
import FalconCardHeader from '../common/FalconCardHeader';
import { Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ButtonIcon from '../common/ButtonIcon';
import ShoppingCartFooter from './shopping-cart/ShoppingCartFooter';
import ShoppingCartTable from './shopping-cart/ShoppingCartTable';
import { isIterableArray } from '../../helpers/utils';
import classNames from 'classnames';
import { useShoppingCart } from '../../hooks/useShoppingCart';
import { toast } from 'react-toastify';
import { useResponsive } from '@farfetch/react-context-responsive';

const JKShoppingCart = () => {
  const { greaterThan } = useResponsive();
  const { shoppingCart, loading, removeCartItem } = useShoppingCart();

  const handleRemoveItem = async id => {
    if (await removeCartItem(id)) {
      //show toast
      toast.success('Item removed from cart');
    }else{
      toast.error('Error removing item');
    }
  }

  return (
    <Card style={{ width: greaterThan.sm ? '75%' : '100%' }} className="mx-auto">
      <FalconCardHeader title={`Shopping Cart (${shoppingCart.length} Items)`} light={false} breakPoint="sm">
        <ButtonIcon
          icon="chevron-left"
          color={classNames({
            'outline-secondary': isIterableArray(shoppingCart),
            primary: !isIterableArray(shoppingCart)
          })}
          size="sm"
          className={classNames({ 'border-300': !isIterableArray(shoppingCart) })}
          tag={Link}
          to="/jamtracks"
        >
          Continue Shopping
        </ButtonIcon>
        {isIterableArray(shoppingCart) && (
          <Button tag={Link} color="primary" size="sm" to="/checkout" className="ml-2">
            Checkout
          </Button>
        )}
      </FalconCardHeader>
      <CardBody className="p-0">
        <ShoppingCartTable shoppingCart={shoppingCart} loading={loading} onRemoveItem={handleRemoveItem} />
      </CardBody>
      {isIterableArray(shoppingCart) && <ShoppingCartFooter />}
    </Card>
  );
};

export default JKShoppingCart;
