import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@farfetch/react-context-responsive';
import { useParams, Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import { getUserDetail, postUserEvent, userOpenedJamTrackWebPlayer } from '../../helpers/rest';
import JKJamTrackPlayer from './JKJamTrackPlayer';
import JKMyJamTrackMixes from './JKMyJamTrackMixes';
import JKCreateCustomMix from './JKCreateCustomMix';
import JKJamTrackResourceLinks from './JKJamTrackResourceLinks';
import { useAuth } from '../../context/UserAuth';
import { useJamTrack } from '../../hooks/useJamTrack';

const JKJamTrackShow = () => {
  const { t } = useTranslation('jamtracks');
  const { greaterThan } = useResponsive();
  const { id } = useParams();
  const { currentUser } = useAuth();
  const { loadJamTrack, jamTrack, jamTrackLoadingStatus } = useJamTrack();

  const fetchUserDetail = async () => {
    try {
      const userId = currentUser.id;
      const resp = await getUserDetail({ id: userId });
      const data = await resp.json();
      await postUserEvent({ name: 'jamtrack_web_player_open' });
      if (!data.first_opened_jamtrack_web_player) {
        setTimeout(async () => {
          await userOpenedJamTrackWebPlayer();
        }, 15000);
      }
    } catch (error) {
      console.log('Error when fetching user detail', error);
    }
  };

  useEffect(() => {
    if (currentUser && jamTrack) {
      fetchUserDetail();
    }
  }, [currentUser, jamTrack]);

  useEffect(() => {
    loadJamTrack(id);
  }, [id]);

  return (
    <>
      {jamTrackLoadingStatus === 'loading' || jamTrackLoadingStatus == 'idel' ? (
        <div>Loading...</div>
      ) : jamTrack ? (
        <>
          <Row>
            <Col>
              <h4>
                JamTrack: {jamTrack.name} by {jamTrack.original_artist}
              </h4>

            </Col>
          </Row>
          <Row className='mb-3'>
            <Col>
              <Link to="/my-jamtracks">{`< Back to My JamTracks`}</Link>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <Card className="mx-auto mb-4">
                <FalconCardHeader title={t('jamtrack.player.title')} titleClass="font-weight-semi-bold" />
                <CardBody className="pt-3">{jamTrack && <JKJamTrackPlayer />}</CardBody>
              </Card>
              <Card className="mx-auto mb-4">
                <FalconCardHeader title={t('jamtrack.my_mixes.title')} titleClass="font-weight-semi-bold" />
                <CardBody className="pt-3">{jamTrack && <JKMyJamTrackMixes />}</CardBody>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="mx-auto mb-4">
                <FalconCardHeader title={t('jamtrack.create_mix.title')} titleClass="font-weight-semi-bold" />
                <CardBody className="pt-3">{jamTrack && <JKCreateCustomMix />}</CardBody>
              </Card>
            </Col>
            <Col sm={12} md={4}>
              <Card className="mx-auto">
                <FalconCardHeader title={t('jamtrack.help_resources.title')} titleClass="font-weight-semi-bold" />
                <CardBody className="pt-3">
                  <JKJamTrackResourceLinks jamTrack={jamTrack} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default JKJamTrackShow;
