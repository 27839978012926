import React from 'react'
import JKModalDialog from '../common/JKModalDialog'

const JKSigningEstimateTimeModal = ({showQueueTime, setShowQueueTime, enqueueTimeMessage}) => {
  return (
    <JKModalDialog
      show={showQueueTime}
      onToggle={() => setShowQueueTime(!showQueueTime)}
      title="Mixdown Queued"
      showFooter={true}
    >
      <div className="d-flex flex-column">
        <p>{enqueueTimeMessage}</p>
      </div>
    </JKModalDialog>
  )
}

export default JKSigningEstimateTimeModal;