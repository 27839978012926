import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useResponsive } from '@farfetch/react-context-responsive'
import { Row, Col } from 'reactstrap'
import JKJamTrackPreview from './JKJamTrackPreview'
import JKJamTrackPurchaseButton from './JKJamTrackPurchaseButton'
import { JamTrackPreviewProvider } from '../../context/JamTrackPreviewContext'


const JKJamTrackItem = ({ jamTrack }) => {
  const { t } = useTranslation('jamtracks')
  const { greaterThan } = useResponsive()
  return (
    <>
      <JamTrackPreviewProvider>
        {greaterThan.sm ? (
          <tr key={`jamtrck-preview-row-${jamTrack.id}`}>
            <td className="track-name-col">
              {jamTrack.name} <br />
              <span className='fs--1'>by {jamTrack.original_artist}</span>
            </td>
            <td className="track-tracks-col fs--1">
              <JKJamTrackPreview jamTrack={jamTrack} />
            </td>
            <td className="purchase-button-col text-center" align='center'>
              <JKJamTrackPurchaseButton jamTrack={jamTrack} />
            </td>
          </tr>
        ) : (
          <Row>
            <Col xs={12} className="mb-3">
              <h5>
                {jamTrack.name}
              </h5>
              <h6 className="fs--1">
                by {jamTrack.original_artist}
              </h6>
            </Col>
            <Col xs={12} className="mb-3">
              <JKJamTrackPreview jamTrack={jamTrack} />
            </Col>
            <Col xs={12} className="mb-3">
              <JKJamTrackPurchaseButton jamTrack={jamTrack} />
            </Col>
          </Row>
        )}
      </JamTrackPreviewProvider>
    </>
  )
}

JKJamTrackItem.propTypes = {
  jamTrack: PropTypes.object.isRequired
}

export default JKJamTrackItem