import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Row, Col } from 'reactstrap';

import { SAMPLE_RATE } from '../../helpers/jamTracks';

import { useJamTrack } from '../../hooks/useJamTrack';
import JKSigningRetryConfirmModal from './JKSigningRetryConfirmModal';
import JKSigningEstimateTimeModal from './JKSigningEstimateTimeModal';
import { markMixdownActive } from '../../helpers/rest';
import JKAudioPlayer from './JKAudioPlayer';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const JKJamTrackPlayer = () => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);
  const fpPromise = FingerprintJS.load();

  const { jamTrack, mixdowns, getMasterAudioUrl, getMixdownAudioUrl, confirmRetryMixdownSigning, showQueueTime, setShowQueueTime, enqueueTimeMessage, retryConfirmMessage, showRetryConfirm, setShowRetryConfirm, enqueueMixdownForSigning, enqueuedMixdown, showEstimatedTime, manageWatchedMixdowns, getStemAudioUrl, trackName } = useJamTrack();

  useEffect(() => {
    if (jamTrack && mixdowns) {
      //mixdowns
      const playableMixdowns = mixdowns.filter(mix => mix.packages.find(p => p.file_type === 'mp3' && p.signing_state === 'SIGNED')?.jam_track_mixdown_id === mix.id);
      const opts = playableMixdowns.map(mix => ({ value: mix.id, label: `Custom Mix: ${mix.name}`, data: { type: 'custom-mix' } }));
      //tracks
      const tracks = jamTrack.tracks.filter(track => track.track_type === 'Track' || track.track_type === 'Click').map(track => ({ value: track.id, label: `Stem: ${trackName(track)}`, data: { type: 'stem' } }));
      opts.push(...tracks);
      //master
      opts.unshift({ value: 'master', label: 'Master Mix', data: { type: 'master' } });
      setOptions(opts);
      if (jamTrack.last_mixdown_id) {
        setSelectedOption(opts.find(opt => opt.value === jamTrack.last_mixdown_id));
      } else if (jamTrack.last_stem_id) {
        setSelectedOption(opts.find(opt => opt.value === jamTrack.last_stem_id));
      } else {
        setSelectedOption(opts[0]);
      }
    }
  }, [jamTrack, mixdowns]);


  const audioFileExtension = url => {
    try {
      const u = new URL(url);
      const ext = u.pathname.split('.').pop();
      return `audio/${ext}`;
    } catch (e) {
      return '';
    }
  };

  const handleOnChange = selectedOption => {
    const option = options.find(opt => opt.value === selectedOption.value);
    setSelectedOption(option);
  };

  useEffect(() => {
    if (!selectedOption) {
      return;
    }

    if (selectedOption.data.type === 'master') {
      const audioUrl = getMasterAudioUrl();
      setAudioUrl(audioUrl);
      markMixdownActive({ id: jamTrack.id, mixdown_id: null });
      if (audioRef.current) {
        audioRef.current.load();
      }

    } else if (selectedOption.data.type === 'custom-mix') {
      //it's a mixdown
      if (!jamTrack.mp3Package || jamTrack.mp3Package?.signing_state !== 'SIGNED') {
        confirmRetryMixdownSigning();
      } else if (jamTrack.mp3Package.signing_state === 'SIGNED') {
        const activeMix = jamTrack.mixdowns.find(mix => mix.id === selectedOption.value);

        getMixdownAudioUrl(activeMix.id).then(audioUrl => {
          setAudioUrl(audioUrl);
          markMixdownActive({ id: jamTrack.id, mixdown_id: activeMix.id });
          if (audioRef.current) {
            audioRef.current.load();
          }
        });

      }
    } else if (selectedOption.data.type === 'stem') {
      const activeTrack = jamTrack.tracks.find(track => track.id === selectedOption.value);

      getStemAudioUrl(activeTrack.id).then(audioUrl => {
        setAudioUrl(audioUrl);
        markMixdownActive({ id: jamTrack.id, mixdown_id: null, stem_id: activeTrack.id });
        if (audioRef.current) {
          audioRef.current.load();
        }
      });
    }
  }, [selectedOption]);

  const retrySigning = () => {
    if (selectedOption) {
      const options = {
        id: selectedOption.value,
        file_type: 'mp3',
        encrypt_type: null,
        sample_rate: SAMPLE_RATE,
        origin: 'player'
      };
      enqueueMixdownForSigning(options);
    }
  }

  useEffect(() => {
    if (enqueuedMixdown && enqueuedMixdown.origin === 'player') {
      showEstimatedTime();
      manageWatchedMixdowns();
    }
  }, [enqueuedMixdown]);

  const handleDownloadClick = async () => {
    if (!selectedOption) {
      return;
    }
    if(!jamTrack.can_download) {
      console.log('Cannot download JamTrack');
      return;
    }
    if (selectedOption.data.type === 'master') {
      await downloadMaster();
    } else if (selectedOption.data.type === 'custom-mix') {
      const activeMix = jamTrack.mixdowns.find(mix => mix.id === selectedOption.value);
      await downloadMixdown(activeMix);
    } else if (selectedOption.data.type === 'stem') {
      await downloadTrack();
    }
  }

  const downloadMaster = async () => {
    const fp = await fpPromise;
    const result = await fp.get();
    const src = `${process.env.REACT_APP_API_BASE_URL}/jamtracks/${jamTrack.id
      }/stems/master/download.mp3?file_type=mp3&download=1&mark=${result.visitorId}`;
    openDownload(src);
  };

  const downloadMixdown = async (mixdown) => {
    const mixdownPackage = mixdown.packages.find(p => p.file_type === 'mp3');
    if (mixdownPackage?.signing_state == 'SIGNED') {
      const fp = await fpPromise;
      const result = await fp.get();
      const src = `${process.env.REACT_APP_API_BASE_URL}/mixdowns/${mixdown.id
        }/download.mp3?file_type=mp3&sample_rate=48&download=1&mark=${result.visitorId}`;
      openDownload(src);
    } else {
      console.log('Mixdown not signed');
    }
  };

  const downloadTrack = async (track) => {
    const activeTrack = jamTrack.tracks.find(track => track.id === selectedOption.value);
    if (activeTrack) {
      const fp = await fpPromise;
      const result = await fp.get();
      const src = `${process.env.REACT_APP_API_BASE_URL}/jamtracks/${jamTrack.id
        }/stems/${activeTrack.id}/download.mp3?file_type=mp3&download=1&mark=${result.visitorId}`;
      openDownload(src);
    }
  }

  const openDownload = async src => {
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  };

  return (
    <>
      <Select
        options={options}
        placeholder="Select Mix"
        onChange={handleOnChange}
        value={selectedOption}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (base) => ({ ...base, zIndex: 9999 })
        }}
      />

      <Row className="mt-2">
        <Col>
          {audioUrl && (
            <JKAudioPlayer audioUrl={audioUrl} onDownloadClick={handleDownloadClick} />
          )}
        </Col>
      </Row>

      <JKSigningRetryConfirmModal
        showRetryConfirm={showRetryConfirm}
        setShowRetryConfirm={setShowRetryConfirm}
        retryConfirmMessage={retryConfirmMessage}
        onConfirm={() => {
          retrySigning();
          setShowRetryConfirm(false);
        }}
      />

      <JKSigningEstimateTimeModal
        showQueueTime={showQueueTime}
        setShowQueueTime={setShowQueueTime}
        enqueueTimeMessage={enqueueTimeMessage}
      />
    </>
  );
};

export default JKJamTrackPlayer;
