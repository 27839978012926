import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getLatencyToUsers } from "../../helpers/rest"

const initialState = {
  status: 'idel',
  latencies: []
}


export const fetchUserLatencies = createAsyncThunk(
  'latency/fetchUserLatencies', 
  async (options, thunkAPI) => {
    const { currentUserId, otherUserIds } = options
    //console.log('_DEBUG_ fetchUserLatencies', currentUserId, otherUserIds)
    const response = await getLatencyToUsers(currentUserId, otherUserIds)
    return response.json()
  }
)

export const latencySlice = createSlice({
  name: 'latency',
  initialState,
  reducers: {
    add: (state, action) => {
      state.latencies.push(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUserLatencies.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(fetchUserLatencies.fulfilled, (state, action) => {
      const records = new Set([...state.latencies, ...action.payload['users']]);
      const unique = [];
      records.map(x => unique.filter(a => a.user_id === x.user_id).length > 0 ? null : unique.push(x))
      state.latencies = unique
      state.status = 'succeeded'
    })
    .addCase(fetchUserLatencies.rejected, (state, action) => {
      state.status = 'failed'
    })
  }
})

export default latencySlice.reducer;