import React, { useState, useEffect, useRef } from 'react';
import { Alert, Col, Row, Button, Card, CardBody, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';
import { isIterableArray } from '../../helpers/utils';
import { useResponsive } from '@farfetch/react-context-responsive';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import JKTooltip from '../common/JKTooltip';
import JKPeopleList from './JKPeopleList';
import JKPeopleSwiper from './JKPeopleSwiper';

import { getGenres, getInstruments } from '../../helpers/rest';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPeople, resetState, loadPrefetched, preFetchPeople } from '../../store/features/musiciansSlice';

function JKPeopleFilter() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false)
  const [instruments, setInstruments] = useState([]);
  const [genres, setGenres] = useState([]);
  const dispatch = useDispatch();

  const page = useRef(0);

  const params = useRef({});
  
  const perPageLimit = 20;

  const { greaterThan } = useResponsive();

  const peopleListRef = useRef();
  
  const people        = useSelector(state => state.musician.people);
  const offset        = useSelector(state => state.musician.offset);
  const loadingStatus = useSelector(state => state.musician.status);
  const prefetched    = useSelector(state => state.musician.prefetched)

  const { register, handleSubmit, setValue, getValues, control } = useForm({
    defaultValues: {
      latency_good: true,
      latency_fair: true,
      latency_high: false,
      proficiency_beginner: true,
      proficiency_intermediate: true,
      proficiency_expert: true,
      instruments: [],
      genres: [],
      joined_within_days: '-1',
      active_within_days: { value: '30', label: 'Within Last 30 Days' },
      from_location: false,
    }
  });

  const { isDirty } = useFormState({ control });

  const hasNextPage = () => offset !== null && offset > 0

  const toggle = () => setShow(!show);

  const lastActiveOpts = [
    { value: '', label: 'Any Range' },
    { value: '1', label: 'Within Last 1 Day' },
    { value: '7', label: 'Within Last 7 Days' },
    { value: '30', label: 'Within Last 30 Days' },
    { value: '90', label: 'Within Last 90 Days' }
  ];

  const joinedOpts = [
    { value: '', label: 'Any Range' },
    { value: '1', label: 'Within Last 1 Day' },
    { value: '7', label: 'Within Last 7 Days' },
    { value: '30', label: 'Within Last 30 Days' },
    { value: '90', label: 'Within Last 90 Days' }
  ];

  const fetchInstruments = async () => {
    await getInstruments()
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        setInstruments(
          data.map(instrument => {
            return {
              value: instrument.id,
              label: instrument.description
            };
          })
        );
      })
      .catch(error => console.log(error));
  };

  const fetchGenres = async () => {
    await getGenres()
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        setGenres(
          data.map(genre => {
            return {
              value: genre.id,
              label: genre.description
            };
          })
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  const goNextPage = () => {
    submitPageQuery()
  };

  const clearFilterOpts = () => {
    setValue('latency_good', true);
    setValue('latency_fair', true);
    setValue('latency_high', false);
    setValue('proficiency_beginner', true);
    setValue('proficiency_intermediate', true);
    setValue('proficiency_expert', true);
    setValue('instruments', null);
    setValue('genres', null);
    setValue('joined_within_days', -1);
    setValue('active_within_days', -1);
    setValue('from_location', false);
  };

  const submitPageQuery = () => {
    handleSubmit(onSubmit)();
  };

  const submitForm = event => {
    event.preventDefault();
    page.current = 0;
    dispatch(resetState());
    handleSubmit(onSubmit)();
    setShow(false);
  };

  const onSubmit = (data) => {
    let genres = [];
    let joined_within_days = '';
    let active_within_days = '';

    if (data.genres) {
      genres = data.genres.map(genre => genre.value);
    }
    if (data.joined_within_days) {
      joined_within_days = data.joined_within_days.value;
    }
    if (data.active_within_days) {
      active_within_days = data.active_within_days.value;
    }

    params.current = { ...data, genres, joined_within_days, active_within_days };

    try {
      
      if(page.current === 0){
        dispatch(fetchPeople({ data: params.current, offset: 0, limit: perPageLimit }));
        page.current += 1
      }else{
        if(prefetched.length > 0){
          dispatch(loadPrefetched());
        }
        if(hasNextPage()){
          dispatch(preFetchPeople({ data: params.current, offset, limit: perPageLimit }));
          page.current += 1;
        }
      }
      
    } catch (error) {
      console.log('error fetching people', error);
    }
  };

  useEffect(() => {
    if(loadingStatus === 'succeeded'){
      if(people.length === 0 && page.current === 1 && !getValues("from_location")){
        //no results found. let's fetch again with from_location enabled
        setValue('from_location', true);
        page.current = 0
        submitPageQuery()
      }else{
        if (page.current === 1 && hasNextPage()) {
          dispatch(preFetchPeople({ data: params.current, offset, limit: perPageLimit }));
          page.current += 1; 
        }
      }
    }
    
  }, [loadingStatus])

  useEffect(() => {
    fetchGenres();
    fetchInstruments();
    submitPageQuery()
  }, []);

  useEffect(() => {
    if (resetFilter) {
      clearFilterOpts();
      dispatch(resetState());
      page.current = 0;
      handleSubmit(onSubmit)();
      setResetFilter(false);
    }
  }, [resetFilter]);

  useEffect(() => {
    setShowLoadMore(prefetched.length > 0 || hasNextPage())
  }, [prefetched, hasNextPage()])


  return (
    <Card>
      <FalconCardHeader title={t('page_title', { ns: 'people' })} titleClass="font-weight-bold">
        <Form inline className="mt-md-0 mt-3">
          <Button
            color="primary"
            className="me-2 mr-2 fs--1"
            onClick={() => setShow(true)}
            data-testid="btnUpdateSearch"
          >
            {t('update_search', { ns: 'people' })}
          </Button>
          <Button
            outline
            color="secondary"
            className="fs--1"
            data-testid="btnResetSearch"
            onClick={() => setResetFilter(true)}
          >
            {t('reset_filters', { ns: 'people' })}
          </Button>
        </Form>
      </FalconCardHeader>
      <CardBody className="pt-0">
        <Modal
          isOpen={show}
          toggle={toggle}
          className="mw-100 mx-1 mr-1 ml-1 mx-md-5 mr-md-5 ml-md-5 mx-xl-10 mr-xl-10 ml-xl-10"
          data-testid="modalUpdateSearch"
        >
          <ModalHeader toggle={toggle}>Update Search</ModalHeader>
          <ModalBody>
            <div className="px-4 pb-4">
              <form>
                <div className="row justify-content-start mt-2">
                  {/* first column */}
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <div className="row justify-content-start">
                      <div className="col-6">
                        <label className="form-label">
                          Latency{' '}
                          <JKTooltip title="Use these checkboxes to search for other musicians by the estimated amount of latency between you and them. Latency is the amount of time it takes for each of your computers to process audio, plus the time it takes for this digital audio to move over the Internet between you." />
                        </label>
                        <div className="form-check">
                          <input
                            {...register('latency_good')}
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={!isDirty}
                            onChange={e => setValue('latency_good', e.target.checked)}
                          />
                          <label className="form-check-label">Good (less than 40ms)</label>
                        </div>
                        <div className="form-check">
                          <input
                            {...register('latency_fair')}
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={!isDirty}
                            onChange={e => setValue('latency_fair', e.target.checked)}
                          />
                          <label className="form-check-label">Fair (40-60ms)</label>
                        </div>
                        <div className="form-check">
                          <input
                            {...register('latency_high')}
                            type="checkbox"
                            className="form-check-input"
                            onChange={e => setValue('latency_high', e.target.checked)}
                          />
                          <label className="form-check-label">Poor (more than 60ms)</label>
                        </div>
                      </div>

                      <div className="col-6">
                        <label className="form-label">
                          Skill Level{' '}
                          <JKTooltip title="Use these checkboxes to search for other musicians by their skill level." />
                        </label>
                        <div className="form-check">
                          <input
                            {...register('proficiency_beginner')}
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={!isDirty}
                            onChange={e => setValue('proficiency_beginner', e.target.checked)}
                          />
                          <label className="form-check-label">Beginner</label>
                        </div>
                        <div className="form-check">
                          <input
                            {...register('proficiency_intermediate')}
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={!isDirty}
                            onChange={e => setValue('proficiency_intermediate', e.target.checked)}
                          />
                          <label className="form-check-label">Intermediate</label>
                        </div>
                        <div className="form-check">
                          <input
                            {...register('proficiency_expert')}
                            type="checkbox"
                            className="form-check-input"
                            defaultChecked={!isDirty}
                            onChange={e => setValue('proficiency_expert', e.target.checked)}
                          />
                          <label className="form-check-label">Expert</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* second column */}
                  <div className="col-12 col-md-6">
                    <label className="form-label" htmlFor="instruments">
                      Instruments{' '}
                      <JKTooltip title="Use these list to search for other musicians who play particular instruments. If you do not select any instruments, we search for any/all instruments. If you select multiple instruments, we search for musicians who play any of these instruments." />
                    </label>
                    <div className="choices">
                      <Controller
                        name="instruments"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={instruments}
                            isMulti
                            closeMenuOnSelect={false}
                            id="selInstruments"
                          />
                        )}
                      />
                    </div>
                    <label className="form-label" htmlFor="genres">
                      Genres{' '}
                      <JKTooltip title="Use these list to search for other musicians who enjoy playing particular musical genres/styles. If you do not select any genres, we search for any/all genres. If you select multiple genres, we search for musicians who play any of these genres." />
                    </label>
                    <div className="choices">
                      <Controller
                        name="genres"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} options={genres} isMulti closeMenuOnSelect={false} id="selGenres" />
                        )}
                      />
                    </div>
                    <label className="form-label" htmlFor="lastActive">
                      Last Active{' '}
                      <JKTooltip title="Use this list to search for other musicians who have been active on JamKazam within a specified time period. More recent activity makes it more likely they will respond if you message or request to connect." />
                    </label>
                    <div className="choices">
                      <Controller
                        name="active_within_days"
                        control={control}
                        render={({ field }) => <Select {...field} options={lastActiveOpts} id="selLastActive" />}
                      />
                    </div>
                    <label className="form-label" htmlFor="joined">
                      Joined JamKazam{' '}
                      <JKTooltip title="Use this list to search for other musicians based on when they joined JamKazam. This can be useful for finding and connecting with newer users." />
                    </label>
                    <div className="choices">
                      <Controller
                        name="joined_within_days"
                        control={control}
                        render={({ field }) => <Select {...field} options={joinedOpts} id="selJoinedWithin" />}
                      />
                    </div>
                    {/* <label className="form-label" htmlFor="from_location">
                      Search by My Location{' '}
                    </label>
                    <div className="form-check">
                      <input
                        {...register('from_location')}
                        type="checkbox"
                        className="form-check-input"
                        onChange={e => setValue('from_location', e.target.checked)}
                      />
                    </div>       */}

                  </div>
                </div>
              </form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="outline-primary" onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button color="primary" onClick={submitForm} data-testid="btnSubmitSearch">
              Search
            </Button>
          </ModalFooter>
        </Modal>

        <div>
          {loadingStatus === 'loading' && people.length === 0 ? (
            <Loader />
          ) : isIterableArray(people) ? (
            <>
              {greaterThan.sm ? (
                <Row className="mb-3 justify-content-between d-none d-md-block">
                  <div className="table-responsive-xl px-2" ref={peopleListRef}>
                    <JKPeopleList 
                      people={people} 
                      goNextPage={goNextPage} 
                      hasNext={showLoadMore} 
                      isLoading={loadingStatus === 'loading'} 
                    />
                  </div>
                </Row>
              ) : (
                <Row className="swiper-container d-block d-md-none" data-testid="peopleSwiper">
                  <JKPeopleSwiper 
                    people={people} 
                    goNextPage={goNextPage} 
                  />
                </Row>
              )}
            </>
          ) : (
            <Row className="p-card">
              <Col>
                <Alert color="info" className="mb-0">
                  No Records!
                </Alert>
              </Col>
            </Row>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default JKPeopleFilter;
