import React from 'react'
import JKModalDialog from '../common/JKModalDialog'

const JKSigningRetryConfirmModal = ({ showRetryConfirm, setShowRetryConfirm, retryConfirmMessage, onConfirm }) => {
  return (
    <JKModalDialog
      show={showRetryConfirm}
      onToggle={() => setShowRetryConfirm(!showRetryConfirm)}
      title="Retry Download?"
      showFooter={false}
    >
      <div className="d-flex flex-column">
        <p>{retryConfirmMessage}</p>
        <button
          className="btn btn-primary"
          onClick={() => onConfirm()}
        >
          Yes
        </button>
      </div>
    </JKModalDialog>
  )
}

export default JKSigningRetryConfirmModal