import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from "reactstrap";

function JKLocaleSwitch() {
  const { t, i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.value);
  }

  return (
    <ul className="list-inline" data-testid="langSwitch">
      <li className="list-inline-item">
        <Button onClick={changeLanguage} value="en" color="link" size="sm" className={classNames('px-1 py-0', {'font-weight-semi-bold btn-outline-primary': i18n.language === 'en'})}>EN</Button>
      </li>
      <li className="list-inline-item">
        <Button onClick={changeLanguage} value="es" color="link" size="sm" className={ classNames('px-1 py-0', {'font-weight-semi-bold btn-outline-primary': i18n.language === 'es'}) }>ES</Button>
      </li>
    </ul>
  );
}

export default JKLocaleSwitch;
