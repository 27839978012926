import React from 'react'
import PropTypes from 'prop-types'

//import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

//import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

//import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'

import { Card, CardBody, CardHeader } from 'reactstrap'
import JKJamTrackItem from './JKJamTrackItem'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const JKJamTracksSlider = ({ jamTracks, onNextPage, totalJamTracks }) => {
  const [index, setIndex] = React.useState(1)
  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => setIndex(index + 1)}
        onSlideNextTransitionEnd={swiper => {
          if (swiper.isEnd) {
            onNextPage()
          }
        }}
        pagination={{
          clickable: true,
          type: 'custom'
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
      >
        {jamTracks && jamTracks.map((jamTrack) => (
          <SwiperSlide key={`jamtrack-swiper-item-${jamTrack.id}`}>
            <Card className="swiper-card">
              <CardHeader className="bg-200 text-center">
                <h4 className="mt-1">JamTrack</h4>
              </CardHeader>
              <CardBody>
                <JKJamTrackItem jamTrack={jamTrack} viewMode="swipe" />
              </CardBody>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="py-4 px-6 bg-white border-top w-100 fixed-bottom">
        <div className="swiper-pagination d-flex justify-content-center text-center ml-auto mr-auto"
          style={{ width: '100%', position: 'relative' }}>
          {totalJamTracks > 0 && (
            <div className='fs-1'>
              JamTrack {index} of {totalJamTracks}
            </div>
          )}
        </div>
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </div>
    </>
  )
}

JKJamTracksSlider.propTypes = {
  jamTracks: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  onNextPage: PropTypes.func.isRequired,
  totalJamTracks: PropTypes.number.isRequired
}

export default JKJamTracksSlider