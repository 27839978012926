import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const JKJamTrackResourceLinks = ({jamTrack}) => {
  const { t } = useTranslation('jamtracks');
  return (
    <>
      <div className="mb-3">
        <div>
          <a target="_blank" href="https://jamkazam.freshdesk.com/support/solutions/articles/66000501472">
            {t('jamtrack.help_resources.using_overview.title')}
          </a>
        </div>
        {t('jamtrack.help_resources.using_overview.description')}
      </div>
      <div className="mb-3">
        <div>
          <a target="_blank" href="https://jamkazam.freshdesk.com/support/solutions/articles/66000125792">
            {t('jamtrack.help_resources.using_mac_windows.title')}
          </a>
        </div>
        {t('jamtrack.help_resources.using_mac_windows.description')}
      </div>
      <div className="mb-3">
        <div>
          {/* <a target="_blank" href="/jamtracks">
            {t('jamtrack.help_resources.jamtracks_home.title')}
          </a> */}
          <Link to="/jamtracks">{t('jamtrack.help_resources.jamtracks_home.title')}</Link>
        </div>
        {t('jamtrack.help_resources.jamtracks_home.description')}
      </div>
      <div className="mb-3">
        <div>
          {/* <a
            target="_blank"
            href={`https://www.jamkazam.com/client?artist=${encodeURIComponent(
              jamTrack.original_artist
            )}#/jamtrack/search`}
          >
            {t('jamtrack.help_resources.see_more.title')}
          </a> */}
          <Link to={`/jamtracks?artist=${encodeURIComponent(jamTrack.original_artist)}`}>
            {t('jamtrack.help_resources.see_more.title')}
          </Link>
        </div>
        {t('jamtrack.help_resources.see_more.description')}
      </div>
    </>
  );
};

export default JKJamTrackResourceLinks;
