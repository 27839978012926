import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const JKAffiliatePayeeVenmo = ({ affiliateUser, onSubmit, submitting }) => {
  const { t } = useTranslation('affiliate');
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    defaultValues: {
      venmo_user_id: ''
    }
  });

  useEffect(() => {
    setValue('venmo_user_id', affiliateUser?.venmo_user_id || '');
  }, [affiliateUser]);

  const onSubmitVenmo = data => {
    const params = { "venmo_user_id": data.venmo_user_id };
    onSubmit(params);
  }

  return (
    <Card>
      <CardHeader>
        <h5>{t('payee.venmo.title')}</h5>
      </CardHeader>
      <CardBody className="bg-light" style={{ minHeight: 200 }}>
        <small>{t('payee.venmo.help_text')}</small>
        <Form noValidate className="mt-3" onSubmit={handleSubmit(onSubmitVenmo)}>
          <FormGroup>
            <Label for="venmo_user_id">{t('payee.venmo.form.venmo_user_id')}</Label>
            <Controller
              name="venmo_user_id"
              control={control}
              rules={{
                required: t('payee.venmo.form.validations.venmo_user_id.required')
              }}
              render={({ field }) => <Input {...field} type="text" className="form-control" id="venmo_user_id" />}
            />
            {errors.venmo_user_id && (
              <div className="text-danger">
                <small>{errors.venmo_user_id.message}</small>
              </div>
            )}
          </FormGroup>
          <div className='d-flex align-content-center justify-content-end'>
            <input type="submit" formNoValidate className="btn btn-primary" value={t('payee.venmo.form.submit')} disabled={submitting} data-testid="paypal_submit" />
            <span className='ml-2'>
              { submitting && <FontAwesomeIcon icon="spinner" />}
            </span>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

JKAffiliatePayeeVenmo.propTypes = {
  affiliateUser: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

JKAffiliatePayeeVenmo.defaultProps = {
  onSubmit: () => {},
  submitting: false
};

export default JKAffiliatePayeeVenmo;
